<template>
  <div>
    <img class="logo" alt="Vue logo" src="./assets/logo.png"> 
    <p class="title">文本处理工具</p>
  </div>
  
  <HelloWorld />

  <Link />
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import Link from './components/Link.vue'
export default {
  name: 'App',
  components: {
    HelloWorld,
    Link
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 4px;
}

.logo {
  width: 32px;
  height: 32px;
  float: left;
}
.title {
  margin-left: 40px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  color: #1b0b67;
}
</style>
