<template>
    <div class="content">
      <div class="tool-bar">
        <span class="text-title">© 2024 ueg.cc 沪ICP备2021029308号</span>
        <span class="text-title">友情链接:</span>
        <a class="text-title" href="https://erp.ueg.cc">U-ERP</a>
        <a class="text-title" href="https://blog.xiejijun.cn">blog</a>
      </div>
    </div>
  </template>
  
  <script>
    export default {
      data () {
        return {
          origin: "",
          retsult: ""
        }
      },
      methods: {

      }
    }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  
  .content {
    margin-top: 30px;
    text-align: center;
  }
  .text-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .text-title {
    color: #666;
    margin-left: 4px;
    font-size: 12px;
  
  }
  .text-model {
    color: white;
    background-color: cadetblue;
    font-weight: 600;
    font-size: 14px;
  
  }
  .input-group-num {
    width: 40px;
    margin-left: 2px;
    margin-top: 0px;
  }
  </style>
  